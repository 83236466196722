// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-balicka-lifting-js": () => import("./../../../src/pages/balicka-lifting.js" /* webpackChunkName: "component---src-pages-balicka-lifting-js" */),
  "component---src-pages-biostymulatory-js": () => import("./../../../src/pages/biostymulatory.js" /* webpackChunkName: "component---src-pages-biostymulatory-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-vi-charytatywny-bal-fundacji-mukohelp-js": () => import("./../../../src/pages/blog/vi-charytatywny-bal-fundacji-mukohelp.js" /* webpackChunkName: "component---src-pages-blog-vi-charytatywny-bal-fundacji-mukohelp-js" */),
  "component---src-pages-blog-zabiegi-z-naruszeniem-ciaglosci-skory-tylko-u-lekarza-medycyny-estetycznej-wywiad-js": () => import("./../../../src/pages/blog/zabiegi-z-naruszeniem-ciaglosci-skory-tylko-u-lekarza-medycyny-estetycznej-wywiad.js" /* webpackChunkName: "component---src-pages-blog-zabiegi-z-naruszeniem-ciaglosci-skory-tylko-u-lekarza-medycyny-estetycznej-wywiad-js" */),
  "component---src-pages-bloomea-js": () => import("./../../../src/pages/bloomea.js" /* webpackChunkName: "component---src-pages-bloomea-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-errors-404-js": () => import("./../../../src/pages/errors/404.js" /* webpackChunkName: "component---src-pages-errors-404-js" */),
  "component---src-pages-fizjoterapia-js": () => import("./../../../src/pages/fizjoterapia.js" /* webpackChunkName: "component---src-pages-fizjoterapia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-laseroterapia-js": () => import("./../../../src/pages/laseroterapia.js" /* webpackChunkName: "component---src-pages-laseroterapia-js" */),
  "component---src-pages-lipoliza-iniekcyjna-js": () => import("./../../../src/pages/lipoliza-iniekcyjna.js" /* webpackChunkName: "component---src-pages-lipoliza-iniekcyjna-js" */),
  "component---src-pages-medycyna-regeneracyjna-js": () => import("./../../../src/pages/medycyna-regeneracyjna.js" /* webpackChunkName: "component---src-pages-medycyna-regeneracyjna-js" */),
  "component---src-pages-metamorfozy-js": () => import("./../../../src/pages/metamorfozy.js" /* webpackChunkName: "component---src-pages-metamorfozy-js" */),
  "component---src-pages-mezoterapia-iglowa-js": () => import("./../../../src/pages/mezoterapia-iglowa.js" /* webpackChunkName: "component---src-pages-mezoterapia-iglowa-js" */),
  "component---src-pages-mikronakluwanie-js": () => import("./../../../src/pages/mikronakluwanie.js" /* webpackChunkName: "component---src-pages-mikronakluwanie-js" */),
  "component---src-pages-nici-js": () => import("./../../../src/pages/nici.js" /* webpackChunkName: "component---src-pages-nici-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oczyszczanie-wodorowe-skory-js": () => import("./../../../src/pages/oczyszczanie-wodorowe-skory.js" /* webpackChunkName: "component---src-pages-oczyszczanie-wodorowe-skory-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-pakiet-slubny-js": () => import("./../../../src/pages/pakiet-slubny.js" /* webpackChunkName: "component---src-pages-pakiet-slubny-js" */),
  "component---src-pages-podologia-js": () => import("./../../../src/pages/podologia.js" /* webpackChunkName: "component---src-pages-podologia-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-radiofrekwencja-js": () => import("./../../../src/pages/radiofrekwencja.js" /* webpackChunkName: "component---src-pages-radiofrekwencja-js" */),
  "component---src-pages-terapia-swiatlem-led-js": () => import("./../../../src/pages/terapia-swiatlem-led.js" /* webpackChunkName: "component---src-pages-terapia-swiatlem-led-js" */),
  "component---src-pages-termolifting-js": () => import("./../../../src/pages/termolifting.js" /* webpackChunkName: "component---src-pages-termolifting-js" */),
  "component---src-pages-toksyna-botulinowa-js": () => import("./../../../src/pages/toksyna-botulinowa.js" /* webpackChunkName: "component---src-pages-toksyna-botulinowa-js" */),
  "component---src-pages-wypelniacze-index-js": () => import("./../../../src/pages/wypelniacze/index.js" /* webpackChunkName: "component---src-pages-wypelniacze-index-js" */),
  "component---src-pages-wypelniacze-powiekszanie-ust-js": () => import("./../../../src/pages/wypelniacze/powiekszanie-ust.js" /* webpackChunkName: "component---src-pages-wypelniacze-powiekszanie-ust-js" */),
  "component---src-pages-zapisy-js": () => import("./../../../src/pages/zapisy.js" /* webpackChunkName: "component---src-pages-zapisy-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-pages-zobacz-klinike-js": () => import("./../../../src/pages/zobacz-klinike.js" /* webpackChunkName: "component---src-pages-zobacz-klinike-js" */)
}

